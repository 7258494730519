.popup__button {
  width: 100%;
  height: 50px;
  font-size: 18px;
  line-height: 22px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #000;
  border-radius: 2px;
  border: none;
  transition: visibility 0s, background 0.3s;
  margin-top: 48px;
}

.popup__button:hover {
  background: rgba(0, 0, 0, 0.8);
}

@media screen and (max-width: 568px) {
  .popup__button {
    font-size: 14px;
    line-height: 17px;
  }
}
