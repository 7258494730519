.popup__content_content_image {
  max-width: 75vw;
  max-height: 75vh;
  height: auto;
  width: auto;
  display: flex;
  background: transparent;
  -webkit-background-size: cover;
  background-size: cover;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  padding: 0;
}
