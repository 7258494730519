.card__like-button {
  width: 21px;
  height: 18px;
  background: transparent url('../../../images/like-inactive.svg') no-repeat;
  /*Потом это будет из модификатора*/
  background-size: 21px 18px;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.card__like-button:hover {
  opacity: 0.6;
}
