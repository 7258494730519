.footer__copyright {
  font-size: 18px;
  line-height: 22px;
  color: #545454;
  margin: 0;
}

@media screen and (max-width: 568px) {
  .footer__copyright {
    font-size: 14px;
    line-height: 17px;
  }
}
