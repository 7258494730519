.profile__description {
  font-size: 18px;
  line-height: 22px;
  grid-area: description;
  margin: 0;
}

@media screen and (max-width: 568px) {
  .profile__description {
    font-size: 14px;
    line-height: 17px;
  }
}

@media screen and (max-width: 480px) {
  .profile__description {
    width: 100%;
    margin: 7px 0 0 0;
  }
}
