.auth-form__textfield {
  font-family: Inter, sans-serif;
  background-color: transparent;
  border: 0;
  border-bottom: 2px solid #CCCCCC;
  font-size: 14px;
  padding: 13px 0;
  width: 100%;
  color: #fff;
}

.auth-form__textfield::-webkit-input-placeholder {color:#CCCCCC;}
.auth-form__textfield::-moz-placeholder          {color:#CCCCCC;}
.auth-form__textfield:-moz-placeholder           {color:#CCCCCC;}
.auth-form__textfield:-ms-input-placeholder      {color:#CCCCCC;}
.auth-form__textfield::placeholder               {color:#CCCCCC;}
