.popup__title {
  margin: 0;
  font-size: 24px;
  line-height: 30px;
}

@media screen and (max-width: 568px) {
  .popup__title {
    font-size: 18px;
    line-height: 22px;
  }
}
