.profile__add-button {
  width: 150px;
  height: 50px;
  background: transparent url("../../../images/add-icon.svg") center no-repeat;
  background-size: 22px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  /*Че это*/
  border: 2px solid #fff;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  cursor: pointer;
  margin-left: auto;
}

.profile__add-button:hover {
  opacity: 0.6;
}

@media screen and (max-width: 740px) {
  .profile__add-button {
    width: 50px;
    height: 50px;
  }
}

@media screen and (max-width: 480px) {
  .profile__add-button {
    width: 100%;
  }
}
