@import url('./__description/profile__description.css');
@import url('./__add-button/profile__add-button.css');
@import url('./__edit-button/profile__edit-button.css');
@import url('./__info/profile__info.css');
@import url('./__title/profile__title.css');
@import url('./__image/profile__image.css');

.profile {
  font-family: 'Inter', Arial, sans-serif;
  color: #fff;
  display: flex;
  align-items: center;
  padding: 36px 0;
  /*Если подгонять под ПЭПЭ, то тут padding: 40px 0; Проявите лоялити*/
}

@media screen and (max-width: 480px) {
  .profile {
    padding: 43px 0;
    flex-direction: column;
    text-align: center;
  }
}
