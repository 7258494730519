.card__title {
  max-width: 220px;
  font-size: 24px;
  line-height: 29px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  /*Либо с точками из макета, либо этот набор свойств для эллипсиса текста в можно лучше*/
  margin: 0;
}
